import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Features from '@solid-ui-blocks/Features/Block06'
// import Clients from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
// import Companies from '@solid-ui-blocks/Companies/Block01'
// import Team from '@solid-ui-blocks/Hero/Block03'
import Faq from '@solid-ui-blocks/Faq/Block02'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'

const Services01 = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='Home' />
      {/* Modals */}
      {/* Blocks */}
      <Header content={content['header']} />
      {/* <Container variant='white' sx={styles.heroContainer}>
        
      </Container> */}
      {/* <Divider space='5' />
      <Divider space='5' />
      <Clients content={content['clients']} />
      <Divider space='4' />
      <Companies content={content['companies']} />
      <Divider space='5' />
      <Divider space='5' />
      <Team content={content['team']} />
       */}
       <Divider space='5' />
      <Divider space='5' />
      <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '50px'}}><h1>Privacy Policy</h1></div>
      <div style={{paddingLeft: '50px'}}><strong>a. Data protection declaration</strong><br /><br /></div>
<div style={{paddingLeft: '75px'}}>Unless stated otherwise below, the provision of your personal data is neither legally nor contractually obligatory, nor required for conclusion of a contract. You are not obliged to provide your data. Not providing it will have no consequences. This only applies as long as the processing procedures below do not state otherwise. “Personal data” is any information relating to an identified or identifiable natural person.<br />

<strong>Responsible person</strong><br />

Contact us at any time. The contact details of the person responsible for data processing can be found on legal notice.<br />

<strong>Collection, processing, and transfer of personal data in orders</strong><br />

 When you submit an order, I only collect and use your personal data insofar as this is necessary for the fulfillment and handling of your order as well as processing of your queries. The provision of data is necessary for conclusion of a contract. Failure to provide it will prevent the conclusion of any contract. The processing will occur on the basis of Article 6(1) b) GDPR and is required for the fulfillment of a contract with you.<br /> 
<strong>Amazon Data</strong><br />
Amazon data is collected through API just for the shipping purpose. It is process in internal office with limited and need-based access. Any data in printed form is securely trashed and dispose-off in a way that it is nearly impossible to read. Your data is transferred here for example to the shipping companies, service providers for handling the order and IT service providers that you have selected. I will comply strictly with legal requirements in every case. The scope of data transmission is restricted to a minimum.<br /><br /></div>

<div style={{paddingLeft: '50px'}}><strong>b. Merchandise management<br /><br /></strong></div>

<div style={{paddingLeft: '75px'}}><strong>Duration of storage</strong><br />
 After contractual processing has been completed, the data is initially stored for the duration of the shipping process, then in accordance with the retention periods prescribed by law, especially tax and commercial law, and then deleted after the period has elapsed. <br />
 <strong>Rights of the affected person</strong><br />
 If the legal requirements are fulfilled, you have the following rights according to art. 15 to 20 GDPR: Right to information, correction, deletion, restriction of processing, data portability. You also have a right of objection against processing based on art. 6 (1) GDPR, and to processing for the purposes of direct marketing, according to art. 21 (1) GDPR.<br />
 <strong>Right to complain to the regulatory</strong><br />
 authority You have the right to complain to the regulatory authority according to art. 77 GDPR if you believe that your data is not being processed legally. <br />
<strong>Right to object</strong><br />
 If the data processing outlined here is based on my legitimate interests in accordance with Article 6(1)f) GDPR, you have the right for reasons arising from your particular situation to object at any time to the processing of your data with future effect. If the objection is successful, I will no longer process the personal data, unless I can demonstrate compelling legitimate grounds for the processing that outright your interests or rights and freedoms, or the processing is intended for the assertion, exercise or defense of legal claims.
</div>
      <Divider space='5' />
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageSiteServicesBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/services", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Services01
